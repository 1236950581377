export const BondAddressLocal = "0x7a83aC10bB805576af2c289Ef72696574Ea7199E"

export const BondAddressKovan = "0xAB0245d3971E5E01C4E5273350B5cB9CBe46aA8B"

export const BondAddressMain = "0x1a024A698EEBdB86ccf3fCaF2F589839bdc066AD"

export function getBondAddress(networkName) {
  switch (networkName) {
    case "local":
    case "private":
      return BondAddressLocal
    case "kovan":
      return BondAddressKovan
    case "main":
      return BondAddressMain
    default:
      return BondAddressLocal
  }
}

export const BondAbi = [
  {
    constant: false,
    inputs: [
      {
        name: "account",
        type: "address"
      }
    ],
    name: "addGovernor",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "staking",
    outputs: [
      {
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256"
      }
    ],
    name: "bonds",
    outputs: [
      {
        name: "",
        type: "bytes32"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "erc20",
    outputs: [
      {
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address"
      },
      {
        name: "",
        type: "uint256"
      }
    ],
    name: "ownerBonds",
    outputs: [
      {
        name: "",
        type: "bytes32"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "governablePeriod",
    outputs: [
      {
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32"
      }
    ],
    name: "bond",
    outputs: [
      {
        name: "value",
        type: "uint256"
      },
      {
        name: "expirationSec",
        type: "uint256"
      },
      {
        name: "creationSec",
        type: "uint256"
      },
      {
        name: "allocated",
        type: "uint256"
      },
      {
        name: "owner",
        type: "address"
      },
      {
        name: "interest",
        type: "uint8"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32"
      }
    ],
    name: "bondIndex",
    outputs: [
      {
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [],
    name: "renounceGovernor",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        name: "account",
        type: "address"
      }
    ],
    name: "isGovernor",
    outputs: [
      {
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "bondId",
        type: "bytes32"
      },
      {
        indexed: false,
        name: "beneficiary",
        type: "address"
      },
      {
        indexed: false,
        name: "amount",
        type: "uint256"
      },
      {
        indexed: false,
        name: "expiration",
        type: "uint256"
      }
    ],
    name: "BondDeposit",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "bondId",
        type: "bytes32"
      },
      {
        indexed: false,
        name: "beneficiary",
        type: "address"
      },
      {
        indexed: false,
        name: "amount",
        type: "uint256"
      }
    ],
    name: "BondWithdraw",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "bondId",
        type: "bytes32"
      },
      {
        indexed: false,
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        name: "beneficiary",
        type: "address"
      },
      {
        indexed: false,
        name: "amount",
        type: "uint256"
      }
    ],
    name: "BondStake",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "bondId",
        type: "bytes32"
      },
      {
        indexed: false,
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        name: "stakingId",
        type: "bytes32"
      },
      {
        indexed: false,
        name: "amount",
        type: "uint256"
      }
    ],
    name: "BondUnstake",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        name: "beneficiary",
        type: "address"
      },
      {
        indexed: false,
        name: "amount",
        type: "uint256"
      }
    ],
    name: "ReimburseBondEject",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "account",
        type: "address"
      }
    ],
    name: "GovernorAdded",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "account",
        type: "address"
      }
    ],
    name: "GovernorRemoved",
    type: "event"
  },
  {
    constant: false,
    inputs: [
      {
        name: "_token",
        type: "address"
      },
      {
        name: "_stakingContract",
        type: "address"
      },
      {
        name: "_governablePeriod",
        type: "uint256"
      }
    ],
    name: "initialize",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        name: "newPeriod",
        type: "uint256"
      }
    ],
    name: "setGovernablePeriod",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        name: "xyoAmount",
        type: "uint256"
      },
      {
        name: "expirationDate",
        type: "uint256"
      }
    ],
    name: "createBond",
    outputs: [
      {
        name: "",
        type: "bytes32"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address"
      },
      {
        name: "_value",
        type: "uint256"
      },
      {
        name: "",
        type: "address"
      },
      {
        name: "_extraData",
        type: "bytes"
      }
    ],
    name: "receiveApproval",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        name: "bondId",
        type: "bytes32"
      },
      {
        name: "beneficiary",
        type: "address"
      },
      {
        name: "stakees",
        type: "address[]"
      },
      {
        name: "amounts",
        type: "uint256[]"
      }
    ],
    name: "stake",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        name: "bondId",
        type: "bytes32"
      }
    ],
    name: "isExpired",
    outputs: [
      {
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "numBonds",
    outputs: [
      {
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        name: "owner",
        type: "address"
      }
    ],
    name: "numOwnerBonds",
    outputs: [
      {
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        name: "beneficiary",
        type: "address"
      }
    ],
    name: "unallocatedBondStake",
    outputs: [
      {
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        name: "beneficiary",
        type: "address"
      }
    ],
    name: "reimburseUnallocatedBond",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        name: "beneficiary",
        type: "address"
      }
    ],
    name: "reimburseAndEject",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  }
]
