import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
// import * as serviceWorker from "./serviceWorker"
import "bootswatch/dist/darkly/bootstrap.min.css"
import "./assets/css/custom.scss"
import {CookiesProvider} from "react-cookie"

ReactDOM.render(
  <CookiesProvider>
    <App />{" "}
  </CookiesProvider>,
  document.getElementById("root")
)

// serviceWorker.unregister()
