export const StakingAddressLocal = "0x0AF54A96C89324D7B24367c114C585b551CB8525"

export const StakingAddressKovan = "0x1a2C4a6Ffd72848E7bD63d2177c29a0aC80c6386"

export const StakingAddressMain = "0x0242514106114DEaA99Fd81574142c36Edb03B6D"

export function getStakingAddress(networkName) {
  switch (networkName) {
    case "local":
    case "private":
      return StakingAddressLocal
    case "kovan":
      return StakingAddressKovan
    case "main":
      return StakingAddressMain
    default:
      return StakingAddressLocal
  }
}

export const StakingAbi = [
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "stakeeToStakingIds",
    outputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "xyoToken",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    name: "bondedStake",
    outputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalActiveStake",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "govContract",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
    ],
    name: "stakerStake",
    outputs: [
      {
        name: "totalStake",
        type: "uint256",
      },
      {
        name: "activeStake",
        type: "uint256",
      },
      {
        name: "cooldownStake",
        type: "uint256",
      },
      {
        name: "totalUnstake",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "actionType",
        type: "uint8",
      },
    ],
    name: "isUnstakeAction",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalVotingStake",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "stakingId",
        type: "bytes32",
      },
    ],
    name: "cooldownStake",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "stakingId",
        type: "bytes32",
      },
    ],
    name: "activateStake",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    name: "stakingStakerIndex",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    name: "stakeData",
    outputs: [
      {
        name: "amount",
        type: "uint256",
      },
      {
        name: "stakeBlock",
        type: "uint256",
      },
      {
        name: "unstakeBlock",
        type: "uint256",
      },
      {
        name: "stakee",
        type: "address",
      },
      {
        name: "staker",
        type: "address",
      },
      {
        name: "isActivated",
        type: "bool",
      },
      {
        name: "isCooledDown",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "stakingIds",
        type: "bytes32[]",
      },
    ],
    name: "withdrawManyStake",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalCooldownStake",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "stakingId",
        type: "bytes32",
      },
    ],
    name: "unstake",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "staker",
        type: "address",
      },
    ],
    name: "totalStakeAndUnstake",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "stakee",
        type: "address",
      },
      {
        name: "startIndex",
        type: "uint256",
      },
      {
        name: "batchSize",
        type: "uint256",
      },
    ],
    name: "resolveGovernanceAction",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "stakerToStakingIds",
    outputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "blockProducerContract",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "stakee",
        type: "address",
      },
      {
        name: "amount",
        type: "uint256",
      },
    ],
    name: "stake",
    outputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "requestChain",
    outputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "blockChain",
    outputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    name: "stakingStakeeIndex",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "stakingId",
        type: "bytes32",
      },
    ],
    name: "withdrawStake",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "penaltyStake",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "stakee",
        type: "address",
      },
    ],
    name: "numStakeeStakes",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    name: "bondStakeIndex",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    name: "requestsById",
    outputs: [
      {
        name: "xyoBounty",
        type: "uint256",
      },
      {
        name: "weiMining",
        type: "uint256",
      },
      {
        name: "createdAt",
        type: "uint256",
      },
      {
        name: "responseBlockNumber",
        type: "uint256",
      },
      {
        name: "requestSender",
        type: "address",
      },
      {
        name: "requestType",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "staker",
        type: "address",
      },
    ],
    name: "numStakerStakes",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32",
      },
      {
        name: "",
        type: "uint256",
      },
    ],
    name: "bondStakes",
    outputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    name: "blocks",
    outputs: [
      {
        name: "previousBlock",
        type: "bytes32",
      },
      {
        name: "supportingData",
        type: "bytes32",
      },
      {
        name: "stakingBlock",
        type: "uint256",
      },
      {
        name: "createdAt",
        type: "uint256",
      },
      {
        name: "creator",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "",
        type: "address",
      },
    ],
    name: "stakeeStake",
    outputs: [
      {
        name: "totalStake",
        type: "uint256",
      },
      {
        name: "activeStake",
        type: "uint256",
      },
      {
        name: "cooldownStake",
        type: "uint256",
      },
      {
        name: "totalUnstake",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "request",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "xyoBounty",
        type: "uint256",
      },
      {
        indexed: false,
        name: "weiMining",
        type: "uint256",
      },
      {
        indexed: false,
        name: "requester",
        type: "address",
      },
      {
        indexed: false,
        name: "xyoSender",
        type: "address",
      },
      {
        indexed: false,
        name: "requestType",
        type: "uint8",
      },
    ],
    name: "RequestSubmitted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "blockHash",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "previousBlock",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "supportingData",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "createdAtBlock",
        type: "uint256",
      },
      {
        indexed: false,
        name: "blockProducer",
        type: "address",
      },
    ],
    name: "BlockCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "beneficiary",
        type: "address",
      },
      {
        indexed: false,
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        name: "stakerStake",
        type: "uint256",
      },
    ],
    name: "WithdrawClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "request",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "responseBlock",
        type: "uint256",
      },
      {
        indexed: false,
        name: "result",
        type: "uint256",
      },
      {
        indexed: false,
        name: "responseType",
        type: "uint8",
      },
    ],
    name: "Response",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "stakingId",
        type: "bytes32",
      },
      {
        indexed: false,
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        name: "staker",
        type: "address",
      },
      {
        indexed: false,
        name: "stakee",
        type: "address",
      },
      {
        indexed: false,
        name: "transition",
        type: "uint8",
      },
    ],
    name: "StakeEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: "beneficiary",
        type: "address",
      },
      {
        indexed: false,
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        name: "totalStake",
        type: "uint256",
      },
      {
        indexed: false,
        name: "activeStake",
        type: "uint256",
      },
      {
        indexed: false,
        name: "cooldownStake",
        type: "uint256",
      },
      {
        indexed: false,
        name: "totalUnstake",
        type: "uint256",
      },
    ],
    name: "EjectEvent",
    type: "event",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_token",
        type: "address",
      },
      {
        name: "_blockProducerContract",
        type: "address",
      },
      {
        name: "_governanceContract",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getLatestBlock",
    outputs: [
      {
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
      {
        name: "",
        type: "address",
      },
      {
        name: "_extraData",
        type: "bytes",
      },
    ],
    name: "receiveApproval",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "from",
        type: "address",
      },
      {
        name: "request",
        type: "bytes32",
      },
      {
        name: "xyoBounty",
        type: "uint256",
      },
      {
        name: "xyoSender",
        type: "address",
      },
      {
        name: "requestType",
        type: "uint8",
      },
    ],
    name: "submitRequestFrom",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "requestId",
        type: "bytes32",
      },
    ],
    name: "blockForRequest",
    outputs: [
      {
        name: "previousBlock",
        type: "bytes32",
      },
      {
        name: "supportingData",
        type: "bytes32",
      },
      {
        name: "stakingBlock",
        type: "uint256",
      },
      {
        name: "createdAt",
        type: "uint256",
      },
      {
        name: "creator",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "requestId",
        type: "bytes32",
      },
    ],
    name: "supportingDataForRequest",
    outputs: [
      {
        name: "supportingData",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "numRequests",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "numBlocks",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "bondId",
        type: "bytes32",
      },
    ],
    name: "numBondStakes",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "staker",
        type: "address",
      },
    ],
    name: "totalEjectStake",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "beneficiary",
        type: "address",
      },
    ],
    name: "eject",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
]
