import React, {Component} from "react"
import {fromWei, BN} from "web3-utils"
import {Form, Button} from "react-bootstrap"
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact"
import {XyoAbi, getXyoAddress} from "./ABI/XyoTokenAbi"
import {StakingAbi, getStakingAddress} from "./ABI/StakingModelAbi"
import {BondAbi, getBondAddress} from "./ABI/XyoBondAbi"
import SmartContractService from "./SmartContractService"
import {withCookies} from "react-cookie"

let serverUrlLocal =
  "https://id81hbm307.execute-api.us-east-1.amazonaws.com/dev"
let serverUrlKovan =
  "https://id81hbm307.execute-api.us-east-1.amazonaws.com/dev"
let serverUrlMain =
  "https://m4m70xltw4.execute-api.us-east-1.amazonaws.com/production"

export function getServerUrl(networkName) {
  switch (networkName) {
    case "local":
      return serverUrlLocal
    case "kovan":
      return serverUrlKovan
    case "main":
      return serverUrlMain
    default:
      return serverUrlMain
  }
}
export function getSenderId(networkName) {
  switch (networkName) {
    case "local":
      return 1
    case "kovan":
      return 2
    case "main":
      return 2
    default:
      return 1
  }
}

class App extends Component {
  service = new SmartContractService(() => {
    this.setState({
      web3: this.service.web3,
      loggedInAccount: this.service.getCurrentUser(),
    })
    this.loadBlockchainData()
    // window.location.reload(false)
  }, this.props.cookies)
  whichNetwork = "local"

  state = {
    modal1: false,
    modal2: false,
    modal3: false,
  }

  toggle = (nr) => () => {
    let modalNumber = "modal" + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    })
  }

  componentDidMount() {
    this.service.loadWeb3(this.props.cookies).then(() => {})
  }

  loadBalances = async (account) => {
    const {web3} = this.state
    this.setState({isLoading: true})

    if (web3) {
      let netId = await web3.eth.net.getNetworkType()

      try {
        let xyoAddress = getXyoAddress(netId)
        const xyoToken = new web3.eth.Contract(XyoAbi, xyoAddress)

        const xyoBalance = await xyoToken.methods.balanceOf(account).call()
        let scscAddress = getStakingAddress(netId)
        let bondAddress = getBondAddress(netId)
        const stakeTotal = await xyoToken.methods.balanceOf(scscAddress).call()

        const unallocatedBondTotal = await xyoToken.methods
          .balanceOf(bondAddress)
          .call()

        this.setState({
          xyoBalance,
          xyoAddress,
          stakeTotal,
          unallocatedBondTotal,
        })

        await this.fetchStake(scscAddress, bondAddress)
      } catch (err) {
        console.log("Error during request", err)
      }
    }
    this.setState({isLoading: false})
  }

  loadBlockchainData = async () => {
    const {web3} = this.state
    if (web3) {
      const accounts = await web3.eth.getAccounts()
      // console.log("Accounts", accounts[0])
      if (accounts[0]) {
        var updateState = {loggedInAccount: accounts[0]}
        if (!this.state.address) {
          updateState["address"] = accounts[0]
        }
        this.setState(updateState)
        console.log("isLoading blockchain data", this.state)

        await this.loadBalances(this.state.address)
      }
    }
  }
  isEligible = () => {
    if (this.state.totalStakeAndUnstake) {
      var available = new BN(this.state.totalStakeAndUnstake)
      if (!available.eqn(0)) {
        return true
      }
    }
    if (this.state.unallocatedBondStake) {
      var unallocated = new BN(this.state.unallocatedBondStake)
      if (!unallocated.eqn(0)) {
        return true
      }
    }
    return false
  }

  checkEligibility = () => {
    if (!this.state.totalStakeAndUnstake) {
      this.setState({
        eligibleMessage: undefined,

        errorMessage:
          "Please connect to ethereum provider like Metamask or Trustwallet",
      })
      return
    }
    var message,
      errorMessage = undefined
    var available,
      unalloc = new BN(0)
    if (this.state.totalStakeAndUnstake) {
      available = new BN(this.state.totalStakeAndUnstake)
    }
    if (this.state.unallocatedBondStake) {
      unalloc = new BN(this.state.unallocatedBondStake)
    }
    if (available.eqn(0) && unalloc.eqn(0)) {
      errorMessage = `${this.state.address} has no stake available.`
    } else {
      message = `You have ${this.prettyAmt(
        available
      )} stake and ${this.prettyAmt(
        unalloc
      )} bonded stake available for reimbursement.`
    }
    this.setState({
      errorMessage: errorMessage,
      eligibleMessage: message,
    })
  }

  eligibleContainer = () => {
    var message = this.state.errorMessage

    if (!this.isAddress(this.state.address)) {
      message = "**Enter a valid wallet Address"
    }
    if (message) {
      return (
        <p>
          <span className="text-warning">{message}</span>
        </p>
      )
    }
    if (this.state.eligibleMessage) {
      return (
        <p>
          <span className="text-success">{this.state.eligibleMessage}</span>
        </p>
      )
    }
  }
  /**
   * Checks if the given string is an address
   *
   * @method isAddress
   * @param {String} address the given HEX adress
   * @return {Boolean}
   */
  isAddress = function (address) {
    // console.log("Checking Address", address)
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
      console.log("Not Valid Address", address)

      // check if it has the basic requirements of an address
      return false
    } else if (
      /^(0x)?[0-9a-f]{40}$/.test(address) ||
      /^(0x)?[0-9A-F]{40}$/.test(address)
    ) {
      // If it's all small caps or all all caps, return true
      return true
    } else {
      // Otherwise check each case
      return true
    }
  }

  changedAddress = (event) => {
    let address = event.target.value
    if (this.isAddress(address)) {
      // console.log("Event", event.target.value)
      this.setState({
        address: address,
        errorMessage: undefined,
        eligibleMessage: undefined,
      })

      this.loadBalances(address)
    } else {
      this.setState({
        errorMessage: "Invalid address",
      })
    }
  }

  fetchStake = async (scscAddress, bondAddress) => {
    const {web3} = this.state
    if (web3) {
      let curAddress = this.state.address
      console.log("Here curaddrress", curAddress)

      if (curAddress) {
        let netId = await web3.eth.net.getNetworkType()
        console.log("Here curaddrress", netId)

        const scsc = new web3.eth.Contract(StakingAbi, scscAddress)
        const bonds = new web3.eth.Contract(BondAbi, bondAddress)

        const totalStakeAndUnstake = await scsc.methods
          .totalEjectStake(this.state.address)
          .call()

        const unallocatedBondStake = await bonds.methods
          .unallocatedBondStake(this.state.address)
          .call()

        this.setState({
          totalStakeAndUnstake,
          unallocatedBondStake,
          scscAddress,
          bondAddress,
        })
      }
    }
  }
  handleSubmit = async () => {
    const {web3} = this.state

    if (web3) {
      if (!this.isEligible()) {
        return this.checkEligibility()
      }

      let curAddress = this.state.address
      if (curAddress && this.state.loggedInAccount) {
        let netId = await web3.eth.net.getNetworkType()

        try {
          let bondAddress = getBondAddress(netId)

          const bondContract = new web3.eth.Contract(BondAbi, bondAddress)

          const receipt = await bondContract.methods
            .reimburseAndEject(curAddress)
            .send({from: this.state.loggedInAccount})
          console.log("Got Receipt", receipt)
          window.location.reload(false)
        } catch (err) {
          console.log("Caught err", err)
        }
      }
    }
  }

  prettyAmt(amount) {
    if (!amount) return "0 XYO"
    let bnAmount = fromWei(amount, "ether")
    let decIndex = bnAmount.indexOf(".")
    if (decIndex > -1) bnAmount = bnAmount.substr(0, decIndex + 3)
    return `${bnAmount} XYO`
  }

  render() {
    return (
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand navbar-dark bg-primary">
          <a
            className="navbar-brand mr-auto"
            href="https://xyo.network/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="XYO"
              className="logo mr-2"
              src={require("./assets/img/XYO_full_white.svg")}
            />
          </a>
          <div className="justify-content-right">
            <ul className="navbar-nav">
              <li className="nav-item d-none d-sm-block">
                <a className="nav-link mr-3" href="#instructions">
                  Instructions
                </a>
              </li>
              <li className="nav-item d-none d-sm-block">
                <a className="nav-link" href="#withdraw">
                  Withdraw
                </a>
              </li>
              <li className="nav-item">
                <MDBContainer>
                  <MDBBtn className="nav-link" onClick={this.toggle(3)}>
                    Rules
                  </MDBBtn>
                  <MDBModal
                    isOpen={this.state.modal3}
                    toggle={this.toggle(3)}
                    size="lg"
                  >
                    <MDBModalHeader toggle={this.toggle(3)}>
                      <div>Xyo Stake Reimbursement</div>
                    </MDBModalHeader>
                    <MDBModalBody>
                      <div className="text-left">
                        <p className="font-weight-bold text-warning">
                          The following rules apply to all token transactions
                          for this stake reimbursement. All users agree to these
                          rules as a condition of participation. No exceptions
                          can be made for any reason.
                        </p>
                        <ul>
                          <li className="mb-3">
                            Users are responsible for their gas fees (paid in
                            ETH) for reimbursement. No exceptions or refunds
                            will be made.
                          </li>
                          <li className="mb-3">
                            Wallet owners are responsible for retaining control
                            of their wallets.
                          </li>
                          <li className="mb-3">
                            Users are responsible for ensuring they are using
                            the correct wallet address before transactions. If
                            ETH is used to send tokens to the wrong address, it
                            will not be refunded by the XYO organization.
                          </li>
                          <li className="mb-3">
                            Transactions cannot be reversed. No tokens will be
                            returned to the smart contract after having been
                            reimbursed to address.
                          </li>
                          <li className="mb-3">
                            The security of each ERC20 wallet is the
                            responsibility of the user. Loss or theft of the
                            wallet preceeding or following reimbursement will
                            not entitle any user to additional tokens or
                            exceptions to these rules.
                          </li>
                          <li className="mb-3">
                            Any unclaimed XYO tokens from this reimbursement
                            will remain locked in the Ethereum smart contract
                            permanently, affecting the total supply of XYO
                            tokens.
                          </li>
                          <li className="mb-3">
                            All XYO Network{" "}
                            <a
                              href="https://xyo.network/terms/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-warning"
                            >
                              terms of service
                            </a>{" "}
                            apply to this stake reimbursement.
                          </li>
                        </ul>
                        <p className="font-weight-bold text-danger text-center text-uppercase text-center">
                          By participating in this Xyo Stake Reimbursement and
                          Xyo World Opening, you are agreeing to these rules.
                        </p>
                      </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn
                        className="btn btn-primary"
                        onClick={this.toggle(3)}
                      >
                        Close
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModal>
                </MDBContainer>
              </li>
            </ul>
          </div>
        </nav>
        <div className="container mb-4">
          <h1 className="my-4">Xyo Stake Reimbursement</h1>
          <div className="d-sm-none text-center mb-4">
            <a href="#instructions" className="btn btn-primary m-2 w-40">
              Instructions
            </a>
            <a href="#withdraw" className="btn btn-primary m-2 w-40">
              Withdraw
            </a>
          </div>
          <p className="blockquote text-left">
            In Jan 2020, XYO foundation planned to pivot away from "the Matrix"
            in Q1 2020. We are reimbursing tokens staked on the Matrix back to
            XYO owners.
          </p>
          <p className="blockquote text-left">
            Thank you for your support in working on this bleeding edge idea.
            Please note that we will revisit this dApp when the tech is in
            demand and able to provide more value to you, the end user.
          </p>
          <p className="blockquote text-left">
            We are excited for a new way for you to use your XYO Tokens. XYO
            World is in active development, and allows XYO Token holders to own
            a piece of the decentralized digital world. Ownership in XYO World
            will increase the many uses of XYO tokens and offer other valuable
            rewards.
          </p>
          <p className="blockquote text-left">
            We share the anticipation with you for the release of XYO World. To
            register for a waitlist, please visit
            <a href="https://world.xyo.network"> XYO World.</a>
          </p>
          <div className="card text-white bg-primary mb-3">
            <div className="card-header">
              <h4 className="card-title m-0">
                <a name="instructions"> </a>Instructions
              </h4>
            </div>
            <div className="card-body">
              <ol className="card-text">
                <li className="mb-3">
                  You are responsible for the gas (transaction fee) for all
                  token reimbursements you initiate. Ensure your Web3 wallet,
                  such as{" "}
                  <a
                    href="https://metamask.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    MetaMask
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://trustwallet.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    Trust Wallet
                  </a>
                  , has ETH available in it for gas. (This dapp defaults to the{" "}
                  <a
                    href="https://www.portis.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    Portis wallet
                  </a>{" "}
                  if you aren't using another Web3 wallet.{" "}
                  <a
                    href="https://support.xy.company/hc/en-us/articles/360045536833#portis"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    Instructions can be found here.
                  </a>
                  ) You can check current gas prices on{" "}
                  <a
                    href="https://etherscan.io/gastracker"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    Etherscan
                  </a>
                  . This transaction should require very little ETH in order to
                  go through.
                </li>
                <li className="mb-3">
                  The following instructions are for MetaMask for desktop
                  computer users, or Trust Wallet for mobile device users. If
                  you are using a different Web3 wallet, please consult the
                  documentation for your wallet when prompted if you're unsure
                  how to proceed.
                </li>

                <li className="mb-3">
                  Once the transaction is completed, the XYO tokens will be
                  available in your wallet. You can transfer your XYO tokens to
                  the ERC20 wallet of your choice.
                </li>
              </ol>
            </div>
          </div>
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-12 col-lg-6 mb-4 p-0 pr-lg-2">
                <div className="card border-secondary h-100">
                  <div className="card-header">
                    <h4 className="card-title m-0">Desktop Users</h4>
                  </div>
                  <div className="card-body text-center">
                    <p className="card-text text-left">
                      For desktop computer users, XYO recommends the MetaMask
                      browser extension for Chrome, Opera, and Firefox.
                    </p>
                    <p className="card-text text-left">
                      Click on the button below for further instructions.
                    </p>
                    <div className="table table-hover mt-4">
                      <MDBContainer className="p-0">
                        <MDBBtn className="m-0" onClick={this.toggle(1)}>
                          <img
                            alt="MetaMask"
                            className="wallet table-light card border-primary shadow"
                            src={require("./assets/img/metamask.svg")}
                          />
                        </MDBBtn>
                        <MDBModal
                          isOpen={this.state.modal1}
                          toggle={this.toggle(1)}
                          size="lg"
                        >
                          <MDBModalHeader toggle={this.toggle(1)}>
                            <div>Using MetaMask to Withdraw Stake</div>
                          </MDBModalHeader>
                          <MDBModalBody>
                            <div className="text-left">
                              <p className="mb-4">
                                The following instructions assume users have
                                some familiarity with Ethereum transactions as
                                they should have already used some of these
                                procedures to purchase XYO in 2018.
                                Nevertheless, we'll include some reminders along
                                the way in case you've forgotten anything.
                              </p>
                              <ol>
                                <li className="mb-1">
                                  If you have not yet installed MetaMask, you
                                  may do so from{" "}
                                  <a
                                    href="https://metamask.io/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    metamask.io
                                  </a>
                                  . (If you already have MetaMask installed and
                                  have ETH available, skip to step 5.) Choose
                                  your preferred browser from their main page
                                  and follow the prompts. Once MetaMask is
                                  installed, you will see the MetaMask fox icon
                                  among your browser extensions.
                                  <div className="text-center container-fluid">
                                    <img
                                      alt="MetaMask Fox Icon"
                                      className="wallet-icon my-2"
                                      src={require("./assets/img/metamask-fox.svg")}
                                    ></img>
                                  </div>
                                </li>
                                <li className="mb-4">
                                  Open MetaMask by clicking the fox icon, and
                                  either log into an existing MetaMask account
                                  or create a new one as prompted by MetaMask.
                                </li>
                                <li className="mb-4">
                                  Make sure your MetaMask wallet has enough ETH
                                  in it for gas. Current Ethereum gas prices can
                                  be found on{" "}
                                  <a
                                    href="https://etherscan.io/gastracker"
                                    target="_new"
                                  >
                                    Etherscan
                                  </a>
                                  . Gas is likely to only be a few cents in U.S.
                                  dollars, so your wallet needs very little ETH.
                                </li>
                                <li className="mb-4">
                                  Once you're logged in, if your MetaMask wallet
                                  is empty, you can either transfer ETH to your
                                  MetaMask wallet or buy some ETH using the
                                  Deposit button. Use{" "}
                                  <i>Directly Deposit Ether</i> to get the
                                  information you need to deposit Ether into
                                  MetaMask from another wallet, or buy Ether
                                  using one of the options presented.
                                  <div className="text-center container-fluid">
                                    <img
                                      alt="MetaMask deposit"
                                      className="my-4 screencap rounded"
                                      src={require("./assets/img/metamask-deposit.png")}
                                    ></img>
                                  </div>
                                  Check the documentation for your preferred
                                  Ether source for instructions on how to
                                  transfer Ether from them to another wallet.
                                </li>
                                <li className="mb-4">
                                  Once you're logged into MetaMask and you have
                                  some ETH in your wallet, you can input the
                                  desired beneficiay address into the
                                  Beneficiary Address section below. Make sure
                                  that this wallet is secure and accessable.
                                </li>
                                <li className="mb-4">
                                  When you select <i>Check Eligibility</i>, you
                                  will be prompted by MetaMask to connect. It
                                  will either prompt you in a pop-up window, or
                                  the browser extension icon will show a number
                                  indicating that you need to open MetaMask and
                                  take an action. In either case, simply accept
                                  the connection when prompted.
                                  <br></br>
                                  <br></br>
                                  <i>Check Eligibility</i> will output the
                                  status of stake and if there's associated
                                  stake. If there's a problem, the dapp will let
                                  you know that your wallet has no stake.
                                </li>
                                <li className="mb-4">
                                  When you're ready to withdraw your stake,
                                  select Get Reimbursement.
                                  <br></br>
                                  <br></br>
                                  You'll be prompted by MetaMask to pay the
                                  necessary gas price for the transaction. It
                                  will estimate the gas and transaction speed
                                  for you, but check current gas prices and use
                                  a number you're comfortable with to ensure the
                                  transaction doesn't fail.
                                  <div className="text-center container-fluid">
                                    <img
                                      alt="Give it gas"
                                      className="my-4 screencap rounded"
                                      src={require("./assets/img/metamask-gas.png")}
                                    ></img>
                                  </div>
                                  If you're confused about gas, please read{" "}
                                  <a
                                    href="https://consensys.net/blog/blockchain-explained/a-guide-to-gas/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    this complete guide from ConsenSys
                                  </a>
                                  , a close friend of XYO and creators of
                                  MetaMask.
                                  <br></br>
                                  <br></br>
                                  When you're ready with the gas price you want
                                  to use, click on <i>Confirm</i>.
                                </li>
                                <li className="mb-4">
                                  You'll be returned to the main interface for
                                  your MetaMask wallet. While your transaction
                                  is waiting to be processed, it will show in a{" "}
                                  <i>Pending</i> queue. Once it's gone through,
                                  MetaMask will notify you and move the
                                  transaction to the <i>History</i> queue with a
                                  status of{" "}
                                  <span className="text-monospace text-success">
                                    CONFIRMED
                                  </span>
                                  .<br></br>
                                  <br></br>
                                  If the transaction fails with a status of{" "}
                                  <span className="text-monospace text-danger">
                                    FAILED
                                  </span>
                                  , try again using more gas.
                                </li>
                                <li className="mb-4">
                                  Once your transaction is{" "}
                                  <span className="text-monospace text-success">
                                    CONFIRMED
                                  </span>
                                  , click the transaction in your MetaMask{" "}
                                  <i>History</i> queue and select the arrow icon
                                  if you wish to view the transaction on
                                  Etherscan.
                                  <div className="text-center container-fluid">
                                    <img
                                      alt="MetaMask confirm"
                                      className="my-4 screencap rounded"
                                      src={require("./assets/img/metamask-confirmed.png")}
                                    ></img>
                                  </div>
                                </li>
                              </ol>
                              <h5 className="text-success text-center">
                                Success! You should now be able to access your
                                bonus XYO tokens from your wallet.
                              </h5>
                              <p className="mt-5 text-muted text-center">
                                Need some help? Reach out!
                              </p>
                              <div className="text-center container-fluid">
                                <a
                                  href="https://support.xy.company/hc/en-us/articles/360045536833"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="FAQ"
                                    className="icon mx-2"
                                    src={require("./assets/img/question.svg")}
                                  ></img>
                                </a>
                                <a
                                  href="https://t.me/xyonetwork"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="Telegram"
                                    className="icon mx-2"
                                    src={require("./assets/img/telegram.svg")}
                                  ></img>
                                </a>
                                <a
                                  href="https://www.reddit.com/r/XYONetwork/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="Reddit"
                                    className="icon mx-2"
                                    src={require("./assets/img/reddit.svg")}
                                  ></img>
                                </a>
                                <a
                                  href="https://support.xy.company/hc/en-us/requests/new"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="Contact"
                                    className="icon mx-2"
                                    src={require("./assets/img/at.svg")}
                                  ></img>
                                </a>
                              </div>
                            </div>
                          </MDBModalBody>
                          <MDBModalFooter>
                            <MDBBtn
                              className="btn btn-success"
                              onClick={this.toggle(1)}
                            >
                              Close
                            </MDBBtn>
                          </MDBModalFooter>
                        </MDBModal>
                      </MDBContainer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-4 p-0 pl-lg-2">
                <div className="card border-secondary h-100">
                  <div className="card-header">
                    <h4 className="card-title m-0">Mobile Users</h4>
                  </div>
                  <div className="card-body text-center">
                    <p className="card-text text-left">
                      For mobile device (smartphone and tablet) users, XYO
                      recommends the Trust Wallet app for Android and iOS.
                    </p>
                    <p className="card-text text-left">
                      Click on the button below for further instructions.
                    </p>
                    <div className="table table-hover mt-4">
                      <MDBContainer className="p-0">
                        <MDBBtn className="m-0" onClick={this.toggle(2)}>
                          <img
                            alt="Trust Wallet"
                            className="wallet table-light card border-primary shadow"
                            src={require("./assets/img/trust.svg")}
                          />
                        </MDBBtn>
                        <MDBModal
                          isOpen={this.state.modal2}
                          toggle={this.toggle(2)}
                          size="lg"
                        >
                          <MDBModalHeader toggle={this.toggle(2)}>
                            <div>Using Trust Wallet to Withdraw Stake</div>
                          </MDBModalHeader>
                          <MDBModalBody>
                            <div className="text-left">
                              <p className="mb-4">
                                The following instructions assume users have
                                some familiarity with Ethereum transactions as
                                they should have already used some of these
                                procedures to purchase XYO in 2018.
                                Nevertheless, we'll include some reminders along
                                the way in case you've forgotten anything.
                              </p>
                              <ol>
                                <li className="mb-4">
                                  If you have not yet installed Trust Wallet,
                                  begin on the{" "}
                                  <a
                                    href="https://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    App Store
                                  </a>{" "}
                                  for iOS (Apple) users or the{" "}
                                  <a
                                    href="https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Play Store
                                  </a>{" "}
                                  for Android users. (If you already have Trust
                                  Wallet installed, skip to step 4.) Download
                                  and install the app, and open it from the
                                  Trust Wallet icon when installation is
                                  complete.
                                  <div className="text-center container-fluid">
                                    <img
                                      alt="Trust Wallet"
                                      className="wallet-icon mt-4"
                                      src={require("./assets/img/trust-icon.svg")}
                                    ></img>
                                  </div>
                                </li>
                                <li className="mb-4">
                                  You can import a wallet of your choice, or
                                  create a new wallet. To create a new wallet,
                                  simply follow the steps when you open the
                                  Trust Wallet app. To import or restore a
                                  wallet you were already using, you'll find
                                  instructions for{" "}
                                  <a
                                    href="https://help.trustwallet.com/hc/en-us/articles/360001044713"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Android here
                                  </a>{" "}
                                  or{" "}
                                  <a
                                    href="https://help.trustwallet.com/hc/en-us/articles/360001063094"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    iOS here
                                  </a>
                                  .
                                </li>
                                <li className="mb-4">
                                  Make sure your Trust Wallet has some Ether
                                  (ETH) in it for gas (a small transaction fee).
                                  This transaction shouldn't take much ETH, you
                                  can check{" "}
                                  <a
                                    href="https://etherscan.io/gastracker"
                                    target="_new"
                                  >
                                    Etherscan
                                  </a>{" "}
                                  for current gas prices. If you're confused
                                  about how gas works, please read{" "}
                                  <a
                                    href="https://consensys.net/blog/blockchain-explained/a-guide-to-gas/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    this complete guide from ConsenSys
                                  </a>
                                  , a close friend of XYO and makers of one of
                                  the most popular ERC20 wallets, MetaMask.
                                  <div className="text-center container-fluid">
                                    <img
                                      alt="Forget Fiat Get ETH"
                                      className="my-4 screencap rounded"
                                      src={require("./assets/img/trust-eth.png")}
                                    ></img>
                                  </div>
                                  From the <i>Wallet</i> icon select{" "}
                                  <i>Ethereum</i>. If you wish to transfer ETH
                                  to your Trust Wallet, select <i>Receive</i>{" "}
                                  and use the information provided by the app to
                                  transfer ETH from the wallet you're using to
                                  store it. (Please see the documentation for
                                  your wallet for information about how to make
                                  a transfer.) If you prefer to buy some ETH,
                                  select the <i>BUY</i> button and follow the
                                  steps in the app to purchase Ether. When
                                  you're finished, you'll be able to see your
                                  ETH from the <i>Wallet</i> icon of the app.
                                </li>
                                <li className="mb-4">
                                  Once you have your Trust Wallet set up and you
                                  have some Ether to use, select the DApps icon
                                  at the bottom of the app screen. At the top of
                                  the DApps screen, enter the URL for this dapp,
                                  which is{" "}
                                  <span className="text-warning text-monospace">
                                    matrixreimbursement.xyo.network
                                  </span>
                                  .
                                </li>
                                <li className="mb-4">
                                  Select the <i>Withdraw</i> button near the top
                                  of the screen, or simply scroll down to{" "}
                                  <i>Choose Beneficiary</i> and enter the
                                  designated address to receive the stake in the{" "}
                                  <i>Enter Wallet Address</i> field.
                                </li>
                                <li className="mb-4">
                                  When you select <i>Check Eligibility</i>, you
                                  may be prompted by the dapp to connect to an
                                  Ethereum provider such as the Trust Wallet app
                                  you're currently using. In the top right
                                  corner of the app, select the Ethereum icon
                                  and then select Ethereum, or reload the dapp
                                  by selecting the circular arrow icon in the
                                  bottom right corner.
                                  <br></br>
                                  <br></br>
                                  It sometimes takes a couple of tries for Trust
                                  Wallet to connect. If needed, close the Trust
                                  Wallet app and re-open it to try again. The
                                  app may also connect automatically with no
                                  further prompting.
                                  <br></br>
                                  <br></br>
                                  Once connected, the dapp will check the wallet
                                  address you input and let you know how many
                                  tokens are available to withdaw.
                                </li>
                                <li className="mb-4">
                                  When you're ready to withdraw your stake,
                                  select <i>Withdraw Stake.</i> You will be
                                  prompted to approve this transaction via a
                                  confirmation screen, which will show your gas
                                  price as <i>Network Fee</i>, which will be the
                                  cost of gas in both ETH and your fiat
                                  currency. Review the transaction and select{" "}
                                  <i>APPROVE</i> at the bottom of the screen
                                  when you're ready.
                                  <div className="text-center container-fluid">
                                    <img
                                      alt="Confirm and approve"
                                      className="my-4 screencap rounded"
                                      src={require("./assets/img/trust-confirm.png")}
                                    ></img>
                                  </div>
                                </li>
                                <li className="mb-4">
                                  You'll be returned to your wallet's main
                                  screen and the transaction will appear at the
                                  top of your transaction queue, first as{" "}
                                  <i>Pending</i>, then as completed.
                                  <div className="text-center container-fluid">
                                    <img
                                      alt="Pending transaction"
                                      className="my-4 screencap rounded"
                                      src={require("./assets/img/trust-queue.png")}
                                    ></img>
                                  </div>
                                </li>
                                <li className="mb-4">
                                  When the transaction is completed, you can
                                  confirm it by selecting it in the Trust Wallet
                                  app, then selecting <i>More Details</i>, which
                                  will show you the transaction on Etherscan.
                                </li>
                              </ol>
                              <h5 className="text-success text-center">
                                Success! You should now be able to access your
                                XYO tokens from the wallet you entered and see
                                your balance reflect the increase as shown in
                                "XYO Balance."
                              </h5>
                              <p className="mt-5 text-muted text-center">
                                Need some help? Reach out!
                              </p>
                              <div className="text-center container-fluid">
                                <a
                                  href="https://support.xy.company/hc/en-us/articles/360045536833"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="FAQ"
                                    className="icon mx-2"
                                    src={require("./assets/img/question.svg")}
                                  ></img>
                                </a>
                                <a
                                  href="https://t.me/xyonetwork"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="Telegram"
                                    className="icon mx-2"
                                    src={require("./assets/img/telegram.svg")}
                                  ></img>
                                </a>
                                <a
                                  href="https://www.reddit.com/r/XYONetwork/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="Reddit"
                                    className="icon mx-2"
                                    src={require("./assets/img/reddit.svg")}
                                  ></img>
                                </a>
                                <a
                                  href="https://support.xy.company/hc/en-us/requests/new"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    alt="Contact"
                                    className="icon mx-2"
                                    src={require("./assets/img/at.svg")}
                                  ></img>
                                </a>
                              </div>
                            </div>
                          </MDBModalBody>
                          <MDBModalFooter>
                            <MDBBtn
                              className="btn btn-success"
                              onClick={this.toggle(2)}
                            >
                              Close
                            </MDBBtn>
                          </MDBModalFooter>
                        </MDBModal>
                      </MDBContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card border-secondary text-left">
            <div className="text-left">
              <div className="card-header">
                <h4 className="m-0">
                  <a name="withdraw"></a>Choose Beneficiary Address
                </h4>
              </div>
              <div className="card-body py-4">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <Form>
                      <Form.Text className="text-info h6">
                        Important: Make sure you still have secure access to
                        this ERC20 wallet's funds.
                      </Form.Text>
                      <Form.Group controlId="formEthAddress">
                        <Form.Control
                          className="shadow"
                          defaultValue={this.state.address}
                          onChange={this.changedAddress}
                          type="ethereum"
                          placeholder="Enter wallet address (0x1234...)"
                        />
                      </Form.Group>
                    </Form>
                    <Button
                      disabled={this.state.isLoading}
                      className="shadow mr-2 mb-2"
                      variant="success"
                      type="button"
                      onClick={this.checkEligibility}
                    >
                      Check Eligibility
                    </Button>
                    <Button
                      disabled={this.state.isLoading}
                      className="shadow mb-2"
                      variant="success"
                      type="button"
                      onClick={this.handleSubmit}
                    >
                      Get Reimbursement
                    </Button>
                  </div>
                  <div className="col-12 col-lg-6 output-msg">
                    {" "}
                    {this.eligibleContainer()}
                  </div>
                </div>
                <div className="card-deck ">
                  <div className="card border-primary card-min m-1 shadow">
                    <div className="card-body">
                      <div className="">
                        <h5 className="card-title">Staked On Matrix</h5>
                        <div className="card-subtitle mb-2 text-info h4">
                          {this.prettyAmt(this.state.totalStakeAndUnstake)}{" "}
                          Staked{" "}
                        </div>
                        <div className="card-subtitle mb-2 text-info h4">
                          {this.prettyAmt(this.state.unallocatedBondStake)}{" "}
                          Unallocated In Bond
                        </div>
                        <div className="small my-2 text-muted">
                          Staking Contract Balance<br></br>
                          {this.prettyAmt(this.state.stakeTotal)}
                        </div>
                        <div className="small my-2 text-muted">
                          Unallocated Bonds Balance<br></br>
                          {this.prettyAmt(this.state.unallocatedBondTotal)}
                        </div>

                        <p className="small bottom-align-text">
                          The amount of XYO you staked on the matrix
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card border-primary card-min m-1 shadow">
                    <div className="card-body">
                      <div className="">
                        <h5 className="card-title">XYO Balance</h5>
                        <div className="card-subtitle mb-2 text-info h4 ">
                          {this.prettyAmt(this.state.xyoBalance)}
                        </div>
                        <div className="card-subtitle my-2 text-muted">
                          {" "}
                          &nbsp;{" "}
                        </div>
                      </div>
                      <div className="d-flex-y justify-content-lg-between">
                        <div className="d-flex flex-grow"></div>
                        <div className="small align-self-baseline">
                          The amount of XYO currently associated to the wallet
                          address.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-muted">
                <p>
                  Logged In As:{" "}
                  {this.state.loggedInAccount
                    ? this.state.loggedInAccount
                    : "Not Logged In"}{" "}
                </p>
                <p>
                  Stake Contract:{" "}
                  {this.state.scscAddress
                    ? this.state.scscAddress
                    : "Not Connected"}
                </p>
                <p>
                  Bond Contract:{" "}
                  {this.state.bondAddress
                    ? this.state.bondAddress
                    : "Not Connected"}
                </p>
                <p>
                  XYO Token Contract:{" "}
                  {this.state.xyoAddress
                    ? this.state.xyoAddress
                    : "Not Connected"}
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="d-flex flex-column-reverse flex-md-row">
              <div className="col-12 col-md-6 text-muted text-left pt-4 pl-0">
                © 2020{" "}
                <a
                  href="https://xyo.network/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  XYO Foundation
                </a>
                <br></br>
                <a
                  href="https://xyo.network/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                –{" "}
                <a
                  href="https://xyo.network/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </div>
              <div className="text-right col-12 col-md-6 pt-3 pr-0">
                <a
                  href="https://support.xy.company/hc/en-us/articles/360045536833"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="FAQ"
                    className="icon mx-2"
                    src={require("./assets/img/question.svg")}
                  ></img>
                </a>
                <a
                  href="https://t.me/xyonetwork"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Telegram"
                    className="icon mx-2"
                    src={require("./assets/img/telegram.svg")}
                  ></img>
                </a>
                <a
                  href="https://www.reddit.com/r/XYONetwork/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Reddit"
                    className="icon mx-2"
                    src={require("./assets/img/reddit.svg")}
                  ></img>
                </a>
                <a
                  href="https://support.xy.company/hc/en-us/requests/new"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Contact"
                    className="icon ml-2"
                    src={require("./assets/img/at.svg")}
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withCookies(App)
